import { Component, OnInit } from '@angular/core';
import { wcbUtility } from '../../shared/helpers/wcbUtility';
import { IDAMTokenInfoModel } from '../../models/IDAMWorkerInfo.model';
import { WcbLoggingJS } from '../../services/wcbLogging';
import { IdamTokenService } from '../../services/idam-token-service';
import { EnrolStatus } from '../../models/directdeposit.constants';
import { SurveybarComponent } from "../surveybar/surveybar.component";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  providers: [SurveybarComponent]
})

export class ConfirmationComponent implements OnInit {

  displayMessage: { [key: string]: string } = {};
  showError: boolean = false;
  showSurvey: boolean = false;

  idamWorkerInfo: IDAMTokenInfoModel = null;

  public constructor(private logger: WcbLoggingJS, private idamTokenSvc: IdamTokenService) {

    const params = history.state.data;

    if (params === undefined) {
      this.showError = true;
      this.idamTokenSvc.GetExtractedWorkerInfo().subscribe(x => { this.idamWorkerInfo = x; wcbUtility.ShowDebug('#IDAM', x) });

      //The following is for quick testing survey
      //this.showSurvey = true;

    } else {
      this.showError = false;
      wcbUtility.ShowDebug("#History State - Confirm", params);

      this.idamWorkerInfo = params['idamWorkerInfo'],

        this.displayMessage.transitNumber = params["transitNumber"];
      this.displayMessage.accountNumber = params["accountNumber"];
      this.displayMessage.bankName = params["bankName"];
      this.displayMessage.bankAddress = params["bankAddress"];
      this.displayMessage.effectiveDate = params["effectiveDate"];
      this.displayMessage.message = params["message"];


      const enrolStatus: EnrolStatus = params["status"];
      if (enrolStatus === EnrolStatus.NewEnrollment) {
        //this.showSurvey = true;
        this.showSurvey = false;    // showSurvey=true will show survey for new DD enrollments
      }
    }
  }

  ngOnInit(): void {
    this.logger.StartTrackPage('timeSpentOnConfirmationDirectDepositPage');
  }

  exitButtonClick() {
    this.logger.TrackEvent("Event_Confirmation_Exit");
    this.signOut()
  }


  private signOut() {
    this.logger.StopTrackPage('timeSpentOnConfirmationDirectDepositPage');

    // this is only for the "Blue Exit button", not for the exit on upper right corner.
    if (this.idamWorkerInfo?.exiturl?.length > 0)
      window.open(this.idamWorkerInfo.exiturl, '_self');
    //else //maybe do this if the string is empty???
    //  window.open('www.worksafebc.com', '_self');
  }
}
