<!--this header can latter get replaced by angular/ux lib header component, the component from angular lib has some spacing issues-->
<div class="page-template">
  <div class="content-wrapper">
    <header class="pb-4">
      <div class="header-thinline"></div>
      <div class="header-topline">
        <div class="header-curve"></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img
              alt=""
              class="img-fluid d-block clickable"
              src="/assets/img/worksafebc-logo.jpg"
              (click)="onHeaderLogoClicked()"
            />
          </div>
          <div
            class="col-6 d-flex justify-content-end align-items-center text-right"
          >
            <a
              class="exitbutton"
              (click)="onExitClicked()"
              href="javascript:void(0)"
              >Exit</a
            >
          </div>
        </div>
      </div>
    </header>

    <ng-container main-content>
      <router-outlet></router-outlet>
    </ng-container>

    <!--DIRECTCONTACT-->
    <section>
      <div class="container">
        <hr />
        <span
          ><b>Have a question about direct deposit? Call us at</b>&nbsp;<a
            href="tel:+18889675377"
            >1.888.967.5377</a
          >.</span
        >
      </div>
    </section>
  </div>
  <footer wsbc-footer [contactUsUrl]="urls.paymentServicesUrl"></footer>
</div>
