import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { IDAMTokenInfoModel } from '../../models/IDAMWorkerInfo.model';
import { wcbUtility } from '../../shared/helpers/wcbUtility';
import { DirectDepositAPIClient, DirectDepositStatus } from '../../services/direct-depositAPI.service';
import { WcbLoggingJS } from '../../services/wcbLogging';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { IdamTokenService } from '../../services/idam-token-service';
import { DatePipe } from '@angular/common';
import { DirectDepositConstants, EnrolStatus } from '../../models/directdeposit.constants';

@Component({
  selector: 'app-banking-update',
  templateUrl: './banking-update.component.html',
  styleUrls: ['./banking-update.component.scss']
})
export class BankingUpdateComponent implements OnInit {
  @ViewChild('cancelModal') public cancelModal: ModalDirective;
  updateErrorMessage: string = '';
  showError: boolean = false;
  showLoading: boolean = true;
  updateMessage: { [key: string]: string } = {};
  processingStatus: string = "";
  disableUpdate: boolean = false;
  workerAddress;
  // todo REVIEW: idamWorkerInfo should be WorkerDirectDepositDTO type instead from the direct-depositAPI.service
  idamWorkerInfo: IDAMTokenInfoModel = new IDAMTokenInfoModel();

  constructor(private modalService: BsModalService,
    private directDepositAPI: DirectDepositAPIClient,
    private router: Router,
    public datepipe: DatePipe,
    private logger: WcbLoggingJS,
    private idamTokenSvc: IdamTokenService) { }


  ngOnInit(): void {
    this.logger.StartTrackPage('timeSpentOnUpdateDirectDepositPage');

    this.idamTokenSvc.GetExtractedWorkerInfo().subscribe(x => {
      //this is async and must include the following...

      this.idamWorkerInfo = x;

      //this does not log to AI and is hidden in Prod
      wcbUtility.ShowDebug('#WorkerSearch searching ...', this.idamWorkerInfo.ccn);

      this.showLoading = true;
      try {
        this.directDepositAPI.worker().subscribe(
          (data: any) => {
            //Grab Worker name if existed
            if (data.workerFirstName && data.workerLastName) {//overwrite idam data if worker profile existed
              this.idamWorkerInfo.firstName = data.workerFirstName;
              this.idamWorkerInfo.lastName = data.workerLastName;
              wcbUtility.ShowDebug("#Worker name found.", data);
            } else {
              wcbUtility.ShowDebug("#Worker name not found, error out.", data);
              this.idamTokenSvc.GetExtractedWorkerInfo().subscribe(x => this.idamWorkerInfo = x);// populate the idam wotker info from the token 

              //Worker Name not always available after cancel, so disable this error protection for now 2021-03-19 10:54:15
              //wcbUtility.GotoErrorPage(this.router, 'Error 1404 ' + DirectDepositConstants.CallSupport); //Worker Name missing from API
            }

            //Status Mappings
            if (this.enumStatusEqual(DirectDepositStatus.EnrolledByPortal, data.status) ||
              this.enumStatusEqual(DirectDepositStatus.UpdatedByPortal, data.status)) {
              this.processingStatus = DirectDepositConstants.Processing;
              //Note: the buttons are only disabled in systest or above, otherwise, it's always enabled
              if (wcbUtility.IsDev() ) { //this check is fail safe
                wcbUtility.ShowDebug("Note: Update & cancel buttons always endabled in dev, test and local. DisableUpdate = ", this.processingStatus.length > 0);
                this.allowUpdate(data); //allow only if this is dev or local or test
              } else {
                this.disableUpdate = true; //disable update
                this.updateEFTInfo(data);
              }
            } else { //Enable update (which is enabled by default)
              this.processingStatus = '';
              this.allowUpdate(data);
            }

            this.showLoading = false;
          },
          (error: any) => {
            console.error('#Worker Get Error', error);
            if (error.status == 404) { //not found, should not come here
              wcbUtility.LogBug('#WorkerSearch not found 404');
              //get worker name here...
              this.update(EnrolStatus.NewEnrollment);
            } else {
              this.showUpdateError(DirectDepositConstants.ServerError + DirectDepositConstants.CallSupport);//some server error
              wcbUtility.LogError(error, '#WorkerGet API Failed');
            }
            this.showLoading = false;
          }
        );
      } catch (ex) {
        //display error message
        this.showUpdateError(DirectDepositConstants.WebError + DirectDepositConstants.CallSupport);//some internal error
        wcbUtility.LogError(ex, '#WorkerGet Web Exception');
        this.showLoading = false;
      }

    }
    );
  }

  updateEFTInfo(data: any): boolean {
    if (data.institutionId && data.bankName && data.transitNumber) {
      this.updateMessage.bankNumber = data.institutionId;
      this.updateMessage.bankName = data.bankName;
      this.updateMessage.bankAddress = data.branchAddress;
      this.updateMessage.transitNumber = data.transitNumber;
      this.updateMessage.accountNumber = data.accountNumber;
      this.updateMessage.effectiveDate = wcbUtility.GetDateString(data.effectiveDate, this.datepipe);
      this.updateMessage.address = wcbUtility.JoinAddress(data.address);
      this.workerAddress = data.address;
      return true;
    }
    else
      return false;
  }

  allowUpdate(data: any):boolean {
    if(!this.updateEFTInfo(data))
    { //no information, redirect, this is the real 404
      wcbUtility.ShowDebug("#Worker profile not found.");
      this.update(EnrolStatus.NewEnrollment);//navigate to new enrollment
      return false;
    }
    return true;
  }

  enumStatusEqual(eString: DirectDepositStatus, eValue: any): boolean {
    //this works for both string and numeric enum index
    return eString === eValue || Object.values(DirectDepositStatus).indexOf(eString) + 1 === eValue;
  }

  showUpdateError(message: string) {
    this.showError = message.length > 0;
    this.updateErrorMessage = message;
  }

  update(status: EnrolStatus = EnrolStatus.Update) {
    this.logger.TrackEvent("Event_Update_UpdateBankingInformation");

    const params = {
      idamWorkerInfo: this.idamWorkerInfo,

      bankNumber: this.updateMessage.bankNumber,
      bankName: this.updateMessage.bankName,
      bankAddress: this.updateMessage.bankAddress,
      transitNumber: this.updateMessage.transitNumber,
      accountNumber: '', //mask account number
      message: 'Update',
      status: status
    }


    const navigationExtras: NavigationExtras = {
      state: { data: params },
      // this is for the query parameter in the future
      // queryParams: {
      //   accountNumber: '12345',
      //   message: ''
      // }
    };

    wcbUtility.ShowDebug('#Update params: ', navigationExtras);
    this.logger.StopTrackPage('timeSpentOnUpdateDirectDepositPage');
    this.router.navigate(['setup'], navigationExtras);
  }



  cancel(cancelModal) {
    cancelModal.show();
  }

  revertCancellation() {
    this.cancelModal.hide();
  }

  processCancellation() {
    this.logger.TrackEvent("Event_Update_CancelDirectDeposit");
    this.cancelModal.hide();

    try {
      this.directDepositAPI.cancelDirectDepositEnrollment().subscribe(
        (data: any) => {
          //console.log('#Save Success: ', data);
          const params = {
            idamWorkerInfo: this.idamWorkerInfo,
            address: wcbUtility.JoinAddress(this.workerAddress, ',')
          }

          const navigationExtras: NavigationExtras = {
            state: { data: params },
          };

          wcbUtility.LogTrace("Event_Cancel_Sucess");
          wcbUtility.ShowDebug('#Update params: ', navigationExtras);
          this.router.navigate(['cancel'], navigationExtras);

        },
        (error: any) => {
          wcbUtility.LogError(error, "#Canel failed at API");
          wcbUtility.GotoErrorPage(this.router, DirectDepositConstants.ServerError + DirectDepositConstants.CallSupport);
        }
      );
    } catch (ex) {
      //display error message
      wcbUtility.LogError(ex, "#Submission failed at WEB");
      wcbUtility.GotoErrorPage(this.router, DirectDepositConstants.WebError + DirectDepositConstants.CallSupport);
    }
  }
}
