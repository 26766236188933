import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard as AdalAuthenticationGuard } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { BankingInfoComponent } from './components/direct-deposit/banking-info.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { IdamAuthenticationGuard, SecurityTypeEnum } from '@wsbc/ux-lib-security';
import { BankingUpdateComponent } from './components/banking-update/banking-update.component';
import { BankingCancelComponent } from './components/banking-cancel/banking-cancel.component';
import { WsbcPageNotFoundComponent } from '@wsbc/ux-lib';
import { BankingErrorComponent } from './components/banking-error/banking-error.component';
const authGuard = environment.security.type === SecurityTypeEnum.IDAM ? IdamAuthenticationGuard : AdalAuthenticationGuard;


const routes: Routes = [
  {
    path: 'error',
    pathMatch: 'full',
    component: BankingErrorComponent,
    canActivate: [authGuard],
    canLoad: [authGuard]
  },
  {
    path: 'setup',
    pathMatch: 'full',
    component: BankingInfoComponent,
    canActivate: [authGuard],
    canLoad: [authGuard]
  },
  {
    path: 'confirmation',
    pathMatch: 'full',
    component: ConfirmationComponent,
    canActivate: [authGuard],
    canLoad: [authGuard]
  },
  {
    path: 'cancel',
    pathMatch: 'full',
    component: BankingCancelComponent,
    canActivate: [authGuard],
    canLoad: [authGuard]
  },
  {
    path: '',
    pathMatch: 'full',
    component: BankingUpdateComponent,
    canActivate: [authGuard],
    canLoad: [authGuard]
  },
  { path: '**', component: WsbcPageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
