import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDAMTokenInfoModel } from '../../models/IDAMWorkerInfo.model';
import { wcbUtility } from '../../shared/helpers/wcbUtility';

@Component({
  selector: 'app-banking-cancel',
  templateUrl: './banking-cancel.component.html',
  styleUrls: ['./banking-cancel.component.scss']
})
export class BankingCancelComponent implements OnInit {

  idamWorkerInfo: IDAMTokenInfoModel = new IDAMTokenInfoModel();
  updateMessage: { [key: string]: string } = {};
  constructor(public datepipe: DatePipe,
    private router: Router
  ) {
    const params = history.state.data;
    if (params === undefined) {
      wcbUtility.GotoErrorPage(this.router, "This page cannot be refreshed.")
    } else {
      //Update
      wcbUtility.ShowDebug("#History State", params);//this is safe, not logged in AI or appear in prod

      this.idamWorkerInfo = params["idamWorkerInfo"];
      this.updateMessage.address = params["address"];
      this.updateMessage.cancelDate = wcbUtility.GetDateString(new Date, this.datepipe);
    }

  }

  ngOnInit(): void {
  }


  exitButtonClick() {
    if (this.idamWorkerInfo?.exiturl?.length > 0)
      window.open(this.idamWorkerInfo.exiturl, '_self');
  }


}
