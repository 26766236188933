import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { WcbLoggingJS } from "../../services/wcbLogging"

export module wcbUtility {

    // [[- specific to direct deposit only
    export function JoinAddress(address, separator: string = "\n"): string {        
        return SanitizeText(address.addressLine1, separator)
            + SanitizeText(address.addressLine2, separator)
            + SanitizeText(address.city, ',')
            + SanitizeText(address.provinceState, '')
            + SanitizeText(address.postalCode, address.country ? separator: '')
            + SanitizeText(address.country, '').trim();
    }

    export function GotoErrorPage(router: Router, errorMessage: string) {
        if(router!=null)
            router.navigate(['error'], { state: { data: { error: errorMessage } } });
      }
    // -]]


    export function SanitizeText(text: string, separator: string): string {
        if (text == null || text == undefined)
            return '';
        else
            return text.replace(/(<([^>]+)>)/gi, "") + separator + ' '; //this is for security reason, prevent inline scripting.
    }

    // this is only showing debug info, no app-insight logging
    export function ShowDebug(...args: any) { //won't log all the time
        if (IsDev() || IsTest()) { //this will only show in dev and systemtest, not prod
            const intro: string = createLogHeader();
            console.debug(intro, ...args);
            //No log for debug, only for trace
            //WARNING: DO NOT put any App-Insight log function here
        }
    }

    //This is the place to add #Telemetric logging
    export function LogTrace(...args: any) { //log anytime
        const intro: string = createLogHeader();
        console.info(intro, ...args);
        logMessage(JoinPrettyObj(args));
    }

    //Log the expected error, e.g. no workers found
    //export function ShowHandledError(...args: any) {
    export function LogError(error: Error | string, ...details: any) {
        logShowBug(false, '#Handled.Error', error, ...details);
    }

    // NOTE: Prefer not to pass error as string, bc it will lose stack trace
    //       Unless your string contain unique information to track the bug
    // This is for showing the unexpected bug or error, but we want to hide from user
    export function LogBug(error: Error | string, ...details: any) {
        logShowBug(IsDebug, '#BUG', error, ...details);
    }

    // NOTE: Prefer not to pass error as string, bc it will lose stack trace
    //       Unless your string contain unique information to track the bug
    // This is for showing the unexpected bug or error, but we want to alert the user
    export function LogBugAlert(error: Error | string, ...details: any) {
        logShowBug(true, 'Unhandled Error', error, ...details);
    }

    //this is internal, not to be called directly
    function logShowBug(showAlert: boolean, title: string, error: Error | string, ...details: any) {
        const intro: string = PrettyDate() + ' ' + createLogHeader() + ' ' + title;
        console.error(intro, error, ...details);
        if (error === undefined) {
            console.error('Error contains no detail');
        } else {
            const myError: Error = ConvertError(error);
            myError.stack += "\n" + JoinPrettyObj(details);
            logError(myError);
            if (showAlert) //Only show if debugging 
            {
                alert(intro + "\n" + prettyError(myError));
            }
        }
    }

    function prettyError(error: Error): string {
        if (error) {
            let message = error.message;
            if (error.stack)
                message = message + "\nStack: " + error.stack;
            return message;
        } else
            return '';
    }

    function ConvertError(error: Error | string): Error {
        if (typeof error === 'string') {
            return new Error(error.toString());
        } else {
            return <Error>error;
        }
    }

    function logMessage(message: string): void {
        try {
            new WcbLoggingJS().TrackTrace(message, {}, 1, 0);
        } catch (e) {
            console.error('#LogTraceFailed', e, message);
        }

    }

    function logError(error: Error): void {
        try {
            new WcbLoggingJS().TrackException(error, {}, {}, 3, false);
        } catch (e) {
            console.error('#LogErrorFailed', e, error);
        }
    }

    export function JoinPrettyObj(objs: any): string {
        let message: string = JoinObj(objs);
        message = message.trim();
        message = message.replace(/(^\[|\]$)/g, ' ').trim();
        //message = message.replace(/(\",\")/g, "\n").trim();
        //message = message.replace(/(^\[|\]$|\",\"|\")/g, ' ').trim();
        return message;
    }

    function JoinObj(objs: any): string {
        const cache = new Set();
        return JSON.stringify(objs, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.has(value)) {
                    // Circular reference found
                    try {
                        // If this value does not reference a parent it can be deduped
                        return JSON.parse(JSON.stringify(value));
                    }
                    catch (err) {
                        // discard key if value cannot be deduped
                        return;
                    }
                }
                // Store value in our set
                cache.add(value);
            }
            return value;
        });
    };

    //DND -- various methods of joining circular object ... for reference
    // export function JoinObj(...args: any): string {
    // if (typeof WeakSet === 'undefined') { //stupid IE
    // } else {
    //     const getCircularReplacer = () => {
    //         const seen = new WeakSet();
    //         return (key: string, value: any) => {
    //             if (typeof value === "object" && value !== null) {
    //                 if (seen.has(value)) {
    //                     return;
    //                 }
    //                 seen.add(value);
    //             }
    //             return value;
    //         };
    //     };
    //     return JSON.stringify(args, getCircularReplacer());
    // }
    //return Object.values(args).join(" :: ");
    // var require: any;
    // var util = require('util');
    // return  util.inspect(args);
    // }


    // This is for showing expected error


    function createLogHeader(elapseStartTime: number | null = null) {
        return TimeNow() + CalculateElapse(elapseStartTime) + ": ";
    }

    export function TimeNow(): string {
        const dt = new Date();
        return dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds();
    }

    export function PrettyDate(specifyDate: Date | null = null): string {
        let dt = new Date();
        if (specifyDate !== null)
            dt = specifyDate
        return dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
    }

    export function CalculateElapse(elapseStartTime: number | null): string {
        let elapseMessage: string = "";
        if (elapseStartTime !== null) {
            const elapse = (performance.now() - elapseStartTime);
            elapseMessage = " [" + msToTime(elapse) + "]";
        }
        return elapseMessage;
    }

    function msToTime(s: number): string {
        // Pad to 2 or 3 digits, default is 2
        const pad = (n: number, z = 2) => ("00" + n).slice(-z); // pad(s / 3.6e6 | 0) + ':' + //no hours
        return pad((s % 3.6e6) / 6e4 | 0) + ":" + pad((s % 6e4) / 1000 | 0) + "." + pad(s % 1000, 4);
    }

    // Check Query string
    function HasQuery(field: string, value: string): boolean {
        try {
            field = encodeURI(field.trim())!.toLowerCase();
            value = encodeURI(value.trim())!.toLowerCase();
            const url = window.location.href!.toLowerCase();
            if (url.indexOf("?" + field + "=" + value) !== -1) {
                return true;
            } else if (url.indexOf("&" + field + "=" + value) !== -1) {
                return true;
            }
        } catch (e) {
            LogBug("#HasQuery Error", e);
        }
        return false;
    }

    function IsHostEnv(host: string, url: string): boolean {
        try {
            return host.startsWith(url);
        } catch (e) {
            LogBug("#IsHostEnv excption", e);
            return false;
        }
    }

    var IsDebug: boolean = false;


    export function CheckDebug(): void {

        // Only enable debug on localhost and development or whenever ?debug=1 is passed
        if (HasQuery("debug", "1")) {
            IsDebug = true;
            ShowDebug("Debug Activated");
        } else if (HasQuery("debug", "0")) {
            IsDebug = false;
            console.debug("Debug Suppressed");
        } else {
            if (!IsProd()) {
                IsDebug = true;
                ShowDebug("Debug Auto Activated");
            }
        }
    }

    export function IsProd(): boolean {
        return GetEnvironment() == '';
    }

    export function IsDev(): boolean {
        const env = GetEnvironment();
        return (env === 'local' || env === 'dev');
    }

    export function IsTest(): boolean {
        const env = GetEnvironment();
        return (env === 'test' );
    }
    

    export function IsLocal(): boolean {
        return GetEnvironment() === 'local';
    }

    export function GetEnvironment(hostParameter?: string): string {

        let envType: string = '';
        try {
            let host: string = '';
            if (hostParameter) {
                host = hostParameter;
            }
            else {
                host = window.location.hostname!.toLowerCase();
            }
            //Util.ShowDebug("Host: " + host);
            if (IsHostEnv(host, 'localhost')) {

                envType = 'local';
            }
            else if (IsHostEnv(host, 'clmdirectdeposit.online.dv.worksafebc.com')) {

                envType = 'dev';
            }
            else if (IsHostEnv(host, 'clmdirectdeposit.online.sy.worksafebc.com')) {

                envType = 'test';
            }
            else if (IsHostEnv(host, 'clmdirectdeposit.online.st.worksafebc.com')) {

                envType = 'stage';
            }
        } catch (e) {
            wcbUtility.LogBug('#GetEnvironment error', e);
        }

        return envType;
    }


    export function GetDateString(srcDate: Date, datepipe: DatePipe) {

        let datestring: string = datepipe.transform(srcDate, 'EEEE, LLLL d, yyyy');
        let aa: string = datepipe.transform(srcDate, 'aa');
        if (aa === "PM")
            aa = 'p.m.';
        else if (aa === 'AM')
            aa = 'a.m.';
        let zz: string = datepipe.transform(srcDate, 'zz');

        if (zz === 'GMT-8' || zz === 'GMT-7')
            zz = 'PT';

        zz = ' (' + zz + ')';

        datestring += ' at ' + datepipe.transform(srcDate, 'h:mm ') + aa + zz;

        return datestring;
    }

    export function obscureCCN(ccn: string): string {

        let obscured: string = "";
        let firstCharacter: string = "";
        const obscuredCharacters: string = "*****"; // 5 
        let finalCharacters: string = "";
        if (ccn) {
          if (ccn.length >= 8) {
            firstCharacter = ccn.substring(0,1);
            finalCharacters = ccn.substring(6, ccn.length);
            obscured = firstCharacter + obscuredCharacters + finalCharacters;
          }
          else {
            obscured = ccn; // CCN is typically 11 characters, so, this should never happen.
          }
        }
        return obscured;
      }

}