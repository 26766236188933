import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { INavMenuItem } from './wsbc-top-navbar.model';


@Component({
  selector: 'wsbc-top-navbar_custom',
  templateUrl: './wsbc-top-navbar.component.html',
  styleUrls: ['./wsbc-top-navbar.component.scss']
})
export class WsbcTopNavbarCustomComponent implements OnInit {
  @Input() menuItems: INavMenuItem[] = [ ];
  //Handeling toggle behaviour for mobile menu value
  showMenu: boolean = false;

  @Input() exitUrlCaption = "Exit";
  // if exitUrl is provided, and exitMenuClicked event handler is not set,
  // it will redirect to the specified URL.
  @Input() exitUrl = "";

  // emit only if exit menu clicked.
  @Output() exitMenuClicked = new EventEmitter<void>();

  exitOnly: boolean = true; // this.menuItems.length === 1 && this.menuItems[0].caption === this.exitUrlCaption;
  

  ngOnInit(): void {
  }

  onClickExit($event: Event) {
    if (this.exitMenuClicked.observers.length) {
      $event.preventDefault();
      this.exitMenuClicked.emit();
    }
  }

  toggleMenu(){
    this.showMenu = ! this.showMenu
  }

  hideMenu(){
    this.showMenu= false;
  }
}
