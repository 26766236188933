import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ctrl-surveybar',
  templateUrl: './surveybar.component.html',
  styleUrls: ['./surveybar.component.scss']
})


export class SurveybarComponent implements OnInit {
  @Input('url') varUrl: string='';
  
  showSurvey: boolean = true;
  
  constructor() {   }

  ngOnInit(): void {
  }

  hideSurvey() {
    this.showSurvey=false;
    //navigation is handled at the link
  }



}
