<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="pagetitle py-4">Your direct deposit has been cancelled</h1>
        <div class="outsidebox">
          <div class="innerbox">
            <div class="confirmationbox">
              <table class="my-3 infopanel d-none d-sm-block">
                <tbody>
                  <tr>
                    <td style="width: 240px"><strong>Name:</strong></td>
                    <td>
                      {{ idamWorkerInfo.firstName }}
                      {{ idamWorkerInfo.lastName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-right: 27px">
                      <strong>Customer Care number:</strong>
                    </td>
                    <td>{{ idamWorkerInfo.ccn }}</td>
                  </tr>
                  <tr>
                    <td style="width: 240px">
                      <strong>Cancellation date:</strong>
                    </td>
                    <td>{{ updateMessage.cancelDate }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="my-3 infopanel d-none d-sm-block"></table>
              <article class="mobile-infopanel d-sm-none pb-3">
                <div><b>Name:</b></div>
                <div class="pb-1">
                  {{ idamWorkerInfo.firstName }} {{ idamWorkerInfo.lastName }}
                </div>
                <div><b>Customer Care number:</b></div>
                <div class="pb-1">{{ idamWorkerInfo.ccn }}</div>
                <div class="mt-3"><b>Cancellation date:</b></div>
                <div class="pb-1">{{ updateMessage.cancelDate }}</div>
              </article>
            </div>
          </div>
        </div>
        <h2 class="mt-3">What to expect next</h2>
        <div class="bullets">
          <ul>
            <li>
              Any payments already in progress will still be made via direct
              deposit.
            </li>
            <li>
              Future payments will be made via cheque and mailed to
              <b>{{ updateMessage.address }}</b
              >.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="py-4">
  <div class="container">
    <div class="row">
      <div class="col">
        <button
          href="workerservices.html"
          class="btn btn-md btn-blue mb-2 mb-width"
          (click)="exitButtonClick()"
        >
          Exit
        </button>
      </div>
    </div>
  </div>
</section>
