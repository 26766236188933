<!-- 3. SURVEY BAR ELEMENT -->
<section class="surveybar py-2 shadow-medium ashgrey" *ngIf="showSurvey">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <ng-content></ng-content>
      </div>
      <div
        class="col-md-12 col-lg-4 text-right d-flex justify-content-end align-items-center"
      >
        <a
          id="cancelBtn"
          class="btn btn-outline-blue mr-2 btn-sm"
          (click)="hideSurvey()"
          >No thanks</a
        >
        <a
          href="{{ varUrl }}"
          target="_blank"
          class="btn btn-sm btn-blue"
          (click)="hideSurvey()"
          >Start survey</a
        >
      </div>
    </div>
  </div>
</section>
