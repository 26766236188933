/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { ApplicationInsights  } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { wcbUtility } from '../shared/helpers/wcbUtility';

enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4
}

enum ConsoleLogLevel {
  None = 0,
  Log = 1,
  Debug = 2,
  Info = 3,
  Warn = 4,
  Error = 5
}

@Injectable()
// this module is a facade for Application Insights currently. It's been abstracted in case we change the way we desire to log and track telemetry and exceptions
// https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md
export class WcbLoggingJS {

  private  _MESSAGE_TAG = '#OS.DirectDeposit.Web.js: ';
  AppInsights = null;

  constructor() {
    const init = new ApplicationInsights({
      config: {
      instrumentationKey: environment.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
      enableDebug: false, //disable throw exception when logging
      enableCorsCorrelation: true  
    } });
    this.AppInsights = init.loadAppInsights();
  }

    private AddMessageTag(message: string): string {
        return this._MESSAGE_TAG + message;
    }

    /**
    * Immediately send all queued telemetry.
    */
    public Flush(): void {
      this.AppInsights.flush();
    }


    
    // Telemetry Initializers always run right before Telemetry is sent to app insights. This allows for injection of information such as username
    // as well as filtering. It acts as a middleware of sorts. 
    // More information can be found at the following URLs:
    //     https://docs.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling#javascript-telemetry-initializers
    //     https://dev.azure.com/wcbbc/ART-Claims/_sprints/taskboard/Team%201/ART-Claims/Iteration%20107?workitem=315529
    // Application Insights should record the IDAM userID along with obscured CCN.
    public setUserContext(userIdentifier: string, userCCN: string) {

      const obscuredCCN: string = wcbUtility.obscureCCN(userCCN);
      this.AppInsights.addTelemetryInitializer(function (envelope) {        

        // this sets the dependency tracker userID and for Page Views
        envelope.ext.user.id = userIdentifier;
        
        // To set custom properties:
        if (envelope.data) {
          console.info("setUserContext() ", userIdentifier, userCCN, obscuredCCN);
          if (envelope.data.baseData) {
            console.log("setUserContext() setting baseData properties.");
            const telemetryItem = envelope.data.baseData;
            telemetryItem.properties = telemetryItem.properties || {};
            telemetryItem.properties["userId"] = userIdentifier;
            telemetryItem.properties["ccn"] = obscuredCCN;
          }
          else {
            console.log("setUserContext() setting envelope.data properties.");
            envelope.data["userId"] = userIdentifier;
            envelope.data["ccn"] = obscuredCCN;
          }
        }
        return true;
      });

    }



    /**
     * Start timing an extended event. Call `stopTrackEvent` to log the event when it ends.
     * @param   name    A string that identifies this event uniquely within the document.
     */
    public StartTrackEvent(name:string): void {
      if(!wcbUtility.IsLocal()) //too slow, disabled in local
      this.AppInsights.startTrackEvent(name);
    }

    /**
     * Log an extended event that you started timing with `startTrackEvent`.
     * @param   name    The string you used to identify this event in `startTrackEvent`.
     * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    public StopTrackEvent(
        name: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }): void {
          if(!wcbUtility.IsLocal()) //too slow, disabled in local
          this.AppInsights.stopTrackEvent(name, properties, measurements);
    }

    /**
     * Starts the timer for tracking a page load time. Use this instead of `trackPageView` if you want to control when the page view timer starts and stops,
     * but don't want to calculate the duration yourself. This method doesn't send any telemetry. Call `stopTrackPage` to log the end of the page view
     * and send the event.
     * @param   name  A string that idenfities this item, unique within this HTML document. Defaults to the document title.
     */
    public StartTrackPage(name?: string) {
      if(!wcbUtility.IsLocal()) //too slow, disabled in local
      this.AppInsights.startTrackPage(name);
    }

    /**
     * Stops the timer that was started by calling `startTrackPage` and sends the pageview load time telemetry with the specified properties and measurements.
     * The duration of the page view will be the time between calling `startTrackPage` and `stopTrackPage`.
     * @param   name  The string you used as the name in `startTrackPage`. Defaults to the document title.
     * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
     * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    public StopTrackPage(
        name?: string,
        url?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }): void {
          if(!wcbUtility.IsLocal()) //too slow, disabled in local
          this.AppInsights.stopTrackPage( {name: name, uri:url}, properties, measurements);
    }

    /**
     * Log a dependency call
     * @param   id    unique id, this is used by the backend o correlate server requests. Use Util.newId() to generate a unique Id.
     * @param   method    represents request verb (GET, POST, etc.)
     * @param   absoluteUrl   absolute url used to make the dependency request
     * @param   pathName  the path part of the absolute url
     * @param   totalTime total request time
     * @param   success   indicates if the request was sessessful
     * @param   resultCode    response code returned by the dependency request
     * @param   properties    map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     * @param   measurements  map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    // public TrackDependency(id: string, method: string, absoluteUrl: string, pathName: string, totalTime: number, success: boolean, resultCode: number,
    //     properties?: { [name: string]: string }, measurements?: { [name: string]: number }): void {

    //       this.appInsights.trackDependency(id, method, absoluteUrl, pathName, totalTime, success, resultCode, properties, measurements);
    // }

    /**
     * Log a user action or other occurrence.
     * @param   name    A string to identify this event in the portal.
     * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     */
    public TrackEvent(
        name: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }):void {

          this.AppInsights.trackEvent( {name: name}, properties, measurements);
    }

    /**
     * Log an exception you have caught.
     * @param   exception   An Error from a catch clause, or the string error message.
     * @param   properties  map[string, string] - additional data used to filter events and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this event, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param   severityLevel   AI.SeverityLevel - severity level
     */
    public TrackException(
        exception: Error,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number },
        severityLevel: SeverityLevel = SeverityLevel.Error,
        showInConsoleLogOutput: boolean = true): void {

        try {
            exception.message = this.AddMessageTag(exception.message);

            if (showInConsoleLogOutput) {
                this.LogConsoleMessage("TrackException:" + exception.message + " name:" + exception.name + " stackTrace:" + exception.stack, ConsoleLogLevel.Error);

            }

            this.AppInsights.trackException({exception: exception, severityLevel:severityLevel} , undefined, properties, measurements);
        } catch (e) {
          console.error(e);//trap errors here, if not it will go to catch all and will get stack overflow
        }
    }


    public LogError(errorMessage: string): void {
        this.TrackException(<Error>{ name: 'DDError', message: errorMessage }, undefined, undefined, SeverityLevel.Error, true);
    }

    /**
     * Log a numeric value that is not associated with a specific event. Typically used to send regular reports of performance indicators.
     * To send a single measurement, use just the first two parameters. If you take measurements very frequently, you can reduce the
     * telemetry bandwidth by aggregating multiple measurements and sending the resulting average at intervals.
     * @param   name    A string that identifies the metric. 
     * @param   average Number representing either a single measurement, or the average of several measurements.
     * @param   sampleCount The number of measurements represented by the average. Defaults to 1.
     * @param   min The smallest measurement in the sample. Defaults to the average.
     * @param   max The largest measurement in the sample. Defaults to the average.
     */
    public TrackMetric(
        name: string,
        average: number,
        sampleCount?: number,
        min?: number,
        max?: number,
        properties?: { [name: string]: string }):void {

          this.AppInsights.trackMetric( {name: name,average: average}, sampleCount, min, max, properties);

    }

    /**
     * Logs that a page or other item was viewed.
     * @param   name  The string you used as the name in `startTrackPage`. Defaults to the document title.
     * @param   url   String - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
     * @param   properties  map[string, string] - additional data used to filter pages and metrics in the portal. Defaults to empty.
     * @param   measurements    map[string, number] - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
     * @param   duration    number - the number of milliseconds it took to load the page. Defaults to undefined. If set to default value, page load time is calculated internally.
     */
    public TrackPageView(
        name?: string,
        url?: string,
        properties?: { [name: string]: string },
        measurements?: { [name: string]: number }, duration?: number): void {

          this.AppInsights.trackPageView( {name: name,uri: url}, properties, measurements);
    }

    /**
     * Log a diagnostic message.
     * @param    message A message string
     * @param    properties  map[string, string] - additional data used to filter traces in the portal. Defaults to empty.
     */
    public TrackTrace(message: string, properties?: { [name: string]: string }, severityLevel?: SeverityLevel, consoleLogLevel: ConsoleLogLevel = ConsoleLogLevel.Log): void {
        try {
            if (consoleLogLevel != ConsoleLogLevel.None) {
                this.LogConsoleMessage(message, consoleLogLevel);
            }

            message = this.AddMessageTag(message); //don't want to add message tag in console
            
            //console.info("#LOGGING",message, properties, severityLevel)

            this.AppInsights.trackTrace( {message: message}, properties, severityLevel);
        } catch (e) {
            //If something bad happened, handle here, don't go to catch all, will end up with stackoverflow because of recursive call
            console.error(e);
        }
    }

    private LogConsoleMessage(message: string, consoleLogLevel: ConsoleLogLevel) {
        switch (consoleLogLevel) {

            case ConsoleLogLevel.None: break; // do nothing
            case ConsoleLogLevel.Info:
                console.log(message);
                break;
            case ConsoleLogLevel.Log:
                console.log(message);
                break;
            case ConsoleLogLevel.Error:
                console.error(message);
                break;
            case ConsoleLogLevel.Warn:
                console.warn(message);
                break;
            case ConsoleLogLevel.Debug:
                console.log(message);
                break;
        }
    }
}


