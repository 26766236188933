// the IDAMWorkerInfoModel is used to hold the information coming in from the IDAM token and does not represent
// the Worker Info DTO (see direct-depositAPI.service)
export class IDAMTokenInfoModel {
  constructor(
    public ccn: string = '', // this comes from the activeorg property in the IDAM token
    public email: string = '',
    public firstName: string = '',
    public lastName: string = '',
    public telephone: string = '',
    public userid: string = '',
    public exiturl: string = ''
  ) {}
}
