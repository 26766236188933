import { SecurityTypeEnum, IdamAuthService } from '@wsbc/ux-lib-security';

export const environment = {
  production: false,
  directDepositBaseUrl: 'https://api-clmdirectdeposit.online.dv.worksafebc.com', //'https://localhost:5001', //

  security: {
    type: SecurityTypeEnum.IDAM,
    stsAuthority: 'https://auth.sy.worksafebc.com/am/oauth2',
    clientId: '4-99-DIRECTDEPOSIT-DEV',
    clientRoot: 'https://clmdirectdeposit.online.dv.worksafebc.com',
    unauthorizedUrl: 'https://op.online.sy.worksafebc.com/NotAuthorized',
    authService: IdamAuthService,
    scopes: ['wsbcworkerprofile', 'wsbcprofile', 'openid'],
  },
  appInsights: {
    instrumentationKey: '36a4b172-10d9-4055-9558-a26c8d8b91e6',
  },
};
