<div class="top-navbar-wrapper">
  <nav class="navbar navbar-expand-lg navbar-light p-0">
    <div
      class="overlay"
      [ngClass]="{ show: showMenu }"
      (click)="hideMenu()"
    ></div>

    <a
      (click)="toggleMenu()"
      type="button"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
    </a>

    <!-- Single navbar menuItem on mobile -->
    <ul class="mobile_nav" *ngIf="menuItems.length === 0">
      <li *ngIf="exitUrl || exitMenuClicked.observers.length">
        <a
          (click)="hideMenu()"
          class="nav-link exitbutton ml-3"
          [attr.href]="exitUrl"
          (click)="onClickExit($event)"
          ><strong>{{ exitUrlCaption }}</strong></a
        >
      </li>
    </ul>

    <!-- Regular navbar -->
    <button
      (click)="toggleMenu()"
      *ngIf="menuItems.length > 0"
      class="navbar-toggler"
      type="button"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <strong
        ><span class="exitbutton menulink"><i class="fa fa-bars mt-1"></i></span
      ></strong>
    </button>
    <div class="collapse navbar-collapse" [ngClass]="{ show: showMenu }">
      <ul class="navbar-nav" [ngClass]="{ show: showMenu }">
        <li *ngFor="let menuItem of menuItems; let i = index" class="nav-item">
          <a
            (click)="hideMenu()"
            class="nav-link"
            [routerLink]="menuItem.route"
            routerLinkActive="router-link-active"
            [routerLinkActiveOptions]="menuItem.routerLinkActiveOptions || {}"
          >
            {{ menuItem.caption }}
          </a>
        </li>
        <li *ngIf="exitUrl || exitMenuClicked.observers.length">
          <a
            (click)="hideMenu()"
            class="nav-link exitbutton ml-3"
            [attr.href]="exitUrl"
            (click)="onClickExit($event)"
            ><strong>{{ exitUrlCaption }}</strong></a
          >
        </li>
      </ul>
    </div>
  </nav>
</div>
