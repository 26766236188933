/* istanbul ignore file */
import { Injectable } from '@angular/core';
import { IDAMTokenInfoModel } from '../models/IDAMWorkerInfo.model';
import { wcbUtility } from '../shared/helpers/wcbUtility';
import { AuthService } from '@wsbc/ux-lib-security';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import jwt_decode from 'jwt-decode';


@Injectable()
export class IdamTokenService {

    private static _extractedWorkerInfoCache: IDAMTokenInfoModel = null;

    constructor(private authSerivce: AuthService) {  }
    
    public GetExtractedWorkerInfo() : Observable<IDAMTokenInfoModel>
    {
        if (IdamTokenService._extractedWorkerInfoCache == null)
        {
            // this returns the token mapped into the IDAMTokenInfoModel
            return this.authSerivce.idToken.pipe(
                map(x => this.extractWorkerInfoFromIDAMJwt(x))
            );            
        }
        else
        {
            return of(IdamTokenService._extractedWorkerInfoCache);
        }
    }

    // Parse the string JWT token received from IDAM and return the decoded JSON object
    private extractWorkerInfoFromIDAMJwt(token: string): IDAMTokenInfoModel {
    
    if (token) {
        if (wcbUtility.IsDev())
        {
            console.info("#tkn", token);
        }
        
        const decodedToken: any = jwt_decode(token);

        IdamTokenService._extractedWorkerInfoCache = {
            ccn: decodedToken.activeorg,
            email: decodedToken.email,
            firstName: decodedToken.firstname,
            lastName: decodedToken.lastname,
            telephone: decodedToken.telephoneNumber,
            userid: decodedToken.userid,
            exiturl: decodedToken.exiturl
        };
    }

    return IdamTokenService._extractedWorkerInfoCache;
    }


}
