import { Component, OnInit } from '@angular/core';
import urls from './strings/urls.json';
import strings from './strings/strings.json';
import { WcbLoggingJS } from './services/wcbLogging';
import { IdamTokenService } from './services/idam-token-service';
import { IDAMTokenInfoModel } from './models/IDAMWorkerInfo.model';
import { wcbUtility } from './shared/helpers/wcbUtility';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  readonly urls = urls;
  readonly strings = strings;
  title = 'worker-directdeposit-web';
  idamWorkerInfo: IDAMTokenInfoModel = new IDAMTokenInfoModel();

  showExitConfirmDialog = false;

  constructor(private logger: WcbLoggingJS,
    private idamTokenSvc: IdamTokenService) { }

  ngOnInit() {

    this.idamTokenSvc.GetExtractedWorkerInfo().subscribe(x => {
      this.idamWorkerInfo = x;
      console.info("#Login Id: ", this.idamWorkerInfo.userid);
      console.info("#Login User CCN: ", this.idamWorkerInfo.ccn);
      console.info("#Login User Email: ", this.idamWorkerInfo.email);
      this.logger.setUserContext(x.userid, x.ccn);
      this.logger.StartTrackPage();

      wcbUtility.ShowDebug("DirectDeposit version 1.220916");//hardcoded for now
    });
  }

  private signOut() {
    if (this.idamWorkerInfo?.exiturl.length > 0) {
      // TODO REVIEW --- Why are we setting timer interval to navigate?
      setTimeout(() => {
        window.open(this.idamWorkerInfo.exiturl, '_self');
      });
    }
  }

  onExitClicked() {
    this.signOut()
  }

  onHeaderLogoClicked() {
    location.replace(this.urls.wsbcUrl);
  }

}
