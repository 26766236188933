<div class="container">
  <div class="outsidebox">
    <div class="innerbox">
      <div class="d-flex">
        <div class="alerticon">
          <div class="alerticon-bg">
            <i class="fa fa-exclamation-triangle"></i>
          </div>
        </div>
        <div>
          <h2 class="alert-title">
            Sorry, something went wrong and we’re unable to process your
            request.
          </h2>
          <p class="alertmessagetext" [innerHTML]="displayError"></p>
        </div>
      </div>
    </div>
  </div>
</div>
