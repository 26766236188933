<a name="top" id="top"></a>

<section *ngIf="showLoading">
  <div class="row">
    <div class="col-12 py-5 text-center">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
        <div class="sk-child sk-bounce4"></div>
      </div>
      <span><strong>Loading</strong></span>
    </div>
  </div>
</section>

<section *ngIf="!showLoading">
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="pagetitle py-4">Update your banking information</h1>
        </div>
      </div>
    </div>
  </section>
  <!--INFOPANEL -->
  <section *ngIf="showError">
    <div class="container mt-3">
      <div class="alertmessagetext" [innerHTML]="updateErrorMessage"></div>
    </div>
  </section>
  <section *ngIf="!showError">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <table class="my-3 d-none d-sm-block">
              <tbody>
                <tr>
                  <td style="padding-right: 27px; width: 270px">
                    <strong>Name:</strong>
                  </td>
                  <td>
                    {{ idamWorkerInfo.firstName }} {{ idamWorkerInfo.lastName }}
                  </td>
                </tr>
                <tr>
                  <td style="padding-right: 27px">
                    <strong>Customer Care number:</strong>
                  </td>
                  <td>{{ idamWorkerInfo.ccn }}</td>
                </tr>
                <tr>
                  <td style="padding-right: 27px"></td>
                </tr>
              </tbody>
            </table>

            <article class="mobile-infopanel d-sm-none pb-3 top-margin">
              <div><strong>Name:</strong></div>
              <div class="pb-1">
                {{ idamWorkerInfo.firstName }} {{ idamWorkerInfo.lastName }}
              </div>
              <div><strong>Customer Care number:</strong></div>
              <div class="pb-1">{{ idamWorkerInfo.ccn }}</div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="row">
          <div class="col">
            <hr />
            <h2 class="">Payments are currently made via direct deposit</h2>
            <p>All payments are made to the following account.</p>
            <table class="my-3 d-none d-sm-block">
              <tbody>
                <tr>
                  <td style="padding-right: 27px; width: 270px">
                    <strong>Institution:</strong>
                  </td>
                  <td>
                    {{ updateMessage.bankNumber }} |
                    {{ updateMessage.bankName }}
                  </td>
                </tr>
                <tr>
                  <td style="padding-right: 27px">
                    <strong>Branch address:</strong>
                  </td>
                  <td>{{ updateMessage.bankAddress }}</td>
                </tr>
                <tr>
                  <td style="padding-right: 27px">
                    <strong>Transit (branch) number:</strong>
                  </td>
                  <td>{{ updateMessage.transitNumber }}</td>
                </tr>
                <tr>
                  <td><strong>Account number:</strong></td>
                  <td>{{ updateMessage.accountNumber }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="my-3 d-none d-sm-block"
              *ngIf="processingStatus.length == 0"
            >
              <tbody>
                <tr>
                  <td style="padding-right: 27px; width: 270px">
                    <strong>Direct deposit last updated:</strong>
                  </td>
                  <td>{{ updateMessage.effectiveDate }}</td>
                </tr>
              </tbody>
            </table>

            <article class="mobile-infopanel d-sm-none pb-3">
              <div><strong>Institution:</strong></div>
              <div class="pb-1">
                {{ updateMessage.bankNumber }} | {{ updateMessage.bankName }}
              </div>
              <div><strong>Branch address:</strong></div>
              <div class="pb-1">{{ updateMessage.bankAddress }}</div>
              <div><strong>Transit (branch) number:</strong></div>
              <div class="pb-1">{{ updateMessage.transitNumber }}</div>
              <div><strong>Account number:</strong></div>
              <div class="pb-1">{{ updateMessage.accountNumber }}</div>
              <div *ngIf="processingStatus.length == 0">
                <strong>Direct deposit last updated:</strong>
              </div>
              <div class="pb-1" *ngIf="processingStatus.length == 0">
                {{ updateMessage.effectiveDate }}
              </div>
            </article>
            <div class="text-danger processing">{{ processingStatus }}</div>
            <div class="my-5">
              <button
                (click)="cancel(cancelModal)"
                [disabled]="disableUpdate"
                class="btn btn-md btn-outline-blue mb-width"
                data-toggle="modal"
                data-target="#cancelDeposits"
              >
                Cancel direct deposit
              </button>
              <button
                (click)="update()"
                [disabled]="disableUpdate"
                class="btn btn-md btn-blue mb-width"
              >
                Update banking information
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>
<!--CONSENT -->
<div
  bsModal
  #cancelModal="bs-modal"
  class="modal fade medium-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name2"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="mediumModalTitle">
          Are you sure you wish to cancel direct deposit?
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cancelModal.hide()"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <p class="">
          If you choose to cancel direct deposit, all future payments will be
          made via cheque and mailed to the following address:
        </p>
        <p>
          <strong style="white-space: pre-line">{{
            updateMessage.address
          }}</strong>
        </p>
        <p>
          If you wish to change your mailing address, you can update your
          address and personal information online or call us at 1.888.967.5377.
        </p>
      </div>
      <div class="modal-footer">
        <a
          class="btn btn-md btn-outline-blue mr-1 mb-1"
          data-dismiss="modal"
          href="javascript:void(0)"
          (click)="processCancellation()"
          >Cancel direct deposit</a
        >
        <a
          class="btn btn-md btn-blue mr-1 mb-1"
          href="javascript:void(0)"
          (click)="revertCancellation()"
          >Keep direct deposit</a
        >
      </div>
    </div>
  </div>
</div>
