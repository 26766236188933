export class DirectDepositConstants {
  public static CallSupport =
    'Please try again. If you would like to set up or update your direct deposit details over the phone, ' +
    "please call us at <a href='tel:18889675377'>1.888.967.5377</a>, Monday through Friday, 8 a.m. to 6 p.m. PT.";
  public static ServerError = ''; //don't display anything for now
  public static WebError = 'Error encountered. ';
  public static Processing =
    'Processing. You can make changes once the last update is processed.';
  public static ChequeInstruction =
    'You can find the necessary banking details on your cheques, account statements, or online banking. Please refer to your financial institution’s website for more information.';
}

export enum EnrolStatus {
  NewEnrollment = 1,
  Update = 2,
}
