import { Component, OnInit, ElementRef, ViewChildren, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControlName,
} from '@angular/forms';
import { DirectDepositModel } from '../../models/directdeposit.model';
import { GenericValidator } from '../../shared/generic-validator';
import { merge, Observable, fromEvent } from 'rxjs';
import { DatePipe } from '@angular/common';
import strings from '../../strings/strings.json';
import { DirectDepositAPIClient, Body2 } from '../../services/direct-depositAPI.service';
import { wcbUtility } from '../../shared/helpers/wcbUtility';
import { IDAMTokenInfoModel } from '../../models/IDAMWorkerInfo.model';
import { WcbLoggingJS } from '../../services/wcbLogging';
import { IdamTokenService } from '../../services/idam-token-service';
import { DirectDepositConstants, EnrolStatus } from '../../models/directdeposit.constants';

const MIN_ACCOUNT_NUMBER_LENGTH = 5;
const MAX_ACCOUNT_NUMBER_LENGTH = 12;
const MIN_TRANSIT_NUMBER_LENGTH = 5;
const MAX_TRANSIT_NUMBER_LENGTH = 5;
@Component({
    selector: 'app-banking-info',
    templateUrl: './banking-info.component.html',
    styleUrls: ['./banking-info.component.scss']
})
export class BankingInfoComponent implements OnInit, AfterViewInit {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
    // reactive forms elements [
    directDepositForm: FormGroup;
    directDepositModel = new DirectDepositModel();
    displayMessage: { [key: string]: string } = {};
    public validationMessages: { [key: string]: { [key: string]: string } };
    public genericValidator: GenericValidator;
    // reactive forms elements ]

    allDigitRegex: RegExp = /[0-9]+/;
    isBankingDetailsGridLoading = false;
    transitInputDisabled = false;
    accountNumberInputDisabled = false;
    bankingDetails: any = null;
    showBankingDetailsGrid = false;
    showBankingDetailsGridError = false;
    bankingDetailsGridError = '';
    transitSelected = false;
    selectedBankingDetailsTransitNumber = '';
    selectedBankingDetailsInstitutionName = '';
    selectedBankingDetailsBranchAddressFull = '';
    accountNumber = '';
    showReviewSection: boolean = false;
    enrollmentStatus: EnrolStatus = EnrolStatus.NewEnrollment;
    selectionBoxVisible: boolean = true;
    continueButtonDisabled: boolean = true;
    submitButtonDisabled: boolean = true;
    showLoading: boolean = false;

    showContinue = true;
    pageTitle: string = 'Set up direct deposit';
    editTitle: string = 'Enter banking details';
    showSetupInstruction: boolean = true;

    chequeInstruction: string = DirectDepositConstants.ChequeInstruction;

    idamWorkerInfo: IDAMTokenInfoModel = new IDAMTokenInfoModel();
    readonly strings = strings;
    showExitConfirmDialog = false;

    updateMessage: { [key: string]: string } = {};
    constructor(
        private fb: FormBuilder,
        private directDepositAPI: DirectDepositAPIClient,
        private router: Router,
        public datepipe: DatePipe,
        private logger: WcbLoggingJS,
        private idamTokenSvc: IdamTokenService) {
        // Setup validation messages for the form controls.
        // You only need to define the message for each control rather than creating a validation function.
        // Note that if you want ANY validations for a control, it needs to be declared in this JSON structure.
        // However, if the message properties for the control are empty, the default settings will be used.
        this.validationMessages = {
            default: {
                required: 'This field is required',
                minlength: 'Minimum characters requirement required',
                maxlength: 'Maximum characters requirement exceeded',
            },
            transitNumber: {
                minlength: 'Please enter a 5-digit transit number.',
            },
            accountNumber: {
                minlength: 'Please enter an account number with at least 5 digits.',
            },
            bankNumber: {
                required: 'Please enter and select a valid transit number',
            },
            submission: {},
        };

        // Define an instance of the validator for use with this form,
        // passing in this form's set of validation messages.
        this.genericValidator = new GenericValidator(this.validationMessages);

        this.directDepositForm = this.fb.group({
            transitNumber: ['', { validators: [Validators.required, Validators.minLength(MIN_TRANSIT_NUMBER_LENGTH)], updateOn: 'blur' }],
            accountNumber: ['', { validators: [Validators.required, Validators.minLength(MIN_ACCOUNT_NUMBER_LENGTH)], updateOn: 'blur' }],
            bankNumber: ['', [Validators.required]],
        });

        const params = history.state.data;

        if (params === undefined) {
            //If it's dev or local, it's OK, otherwise, block it and return to update
            if(wcbUtility.IsDev())
                this.idamTokenSvc.GetExtractedWorkerInfo().subscribe(x => this.idamWorkerInfo = x);// populate the idam wotker info from the token 
            else
                this.router.navigateByUrl('/'); //block it and send them back to update page
        } else {
            //Update
            wcbUtility.ShowDebug("#History State", params);//this is safe, not logged in AI or appear in prod

            this.idamWorkerInfo = params["idamWorkerInfo"];

            this.updateMessage.bankNumber = params["bankNumber"];
            this.updateMessage.bankName = params["bankName"];
            this.updateMessage.bankAddress = params["bankAddress"];
            this.updateMessage.transitNumber = params["transitNumber"];
            this.updateMessage.accountNumber = params["accountNumber"];
            this.updateMessage.message = params["message"];
            this.enrollmentStatus = params["status"];

            if (this.updateMessage.transitNumber && this.updateMessage.bankNumber) {

                this.selectedBankingDetailsTransitNumber = this.updateMessage.transitNumber;
                this.accountNumber = this.updateMessage.accountNumber;
                this.directDepositForm.controls.transitNumber.setValue(this.updateMessage.transitNumber);
                this.directDepositForm.controls.bankNumber.setValue(this.updateMessage.bankNumber);
                this.directDepositForm.controls.accountNumber.setValue(this.updateMessage.accountNumber);
                this.selectedBankingDetailsTransitNumber = this.updateMessage.transitNumber;
                this.selectedBankingDetailsInstitutionName = this.updateMessage.bankNumber + ' | ' + this.updateMessage.bankName;
                this.selectedBankingDetailsBranchAddressFull = this.updateMessage.bankAddress;
                this.transitSelected = true;
                this.selectionBoxVisible = true;
                this.validateForm();
                this.pageTitle = 'Update your banking information';
                this.editTitle = 'Enter your new banking details';
                this.showSetupInstruction = false;
            } else {
                this.showSetupInstruction = true;
                this.editTitle = 'Enter banking details';
                this.pageTitle = 'Setup direct deposit';
            }
        }
    }

    ngOnInit(): void {
      this.logger.StartTrackPage('timeSpentOnSetupDirectDepositPage');
      console.log("#Version 1.230330");
    }

    // this is the generic validator
    ngAfterViewInit(): void {
        // Watch for the blur event from any input element on the form.
        // This is required because the valueChanges does not provide notification on blur
        const controlBlurs: Observable<any>[] = this.formInputElements
            .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

        // Merge the blur event observable with the valueChanges observable
        // so we only need to subscribe once.
        merge(this.directDepositForm.valueChanges, ...controlBlurs).pipe(
            debounceTime(200)
        ).subscribe(() => {
            this.displayMessage = this.genericValidator.processMessages(this.directDepositForm);
        });
    }

    selectTransit(i: number) {
        this.logger.TrackEvent("Event_Setup_SelectTransitFromList");
        this.selectedBankingDetailsTransitNumber = this.bankingDetails[i].transitNumber;
        this.selectedBankingDetailsInstitutionName = this.bankingDetails[i].institutionNumber + ' | ' + this.bankingDetails[i].institutionName;
        this.selectedBankingDetailsBranchAddressFull = this.bankingDetails[i].branchAddress + ' '
            + this.bankingDetails[i].city + ' '
            + this.bankingDetails[i].provinceCode + ' '
            + this.bankingDetails[i].postalCode;
        this.showBankingDetailsGrid = false;
        this.directDepositForm.controls.bankNumber.setValue(this.bankingDetails[i].institutionNumber);
        this.directDepositForm.controls.transitNumber.setValue(this.bankingDetails[i].transitNumber);
        this.transitSelected = true;
        this.selectionBoxVisible = true;

        this.validateForm();
    }

    searchTransit(event: any) {

        this.displayMessage.transitNumber = '';
        this.enableContinue(false);
        this.showBankingDetailsGrid = false;
        this.showBankingDetailsGridError = false;

        this.directDepositForm.controls.transitNumber.setValue(event.target.value);

        if (event && event.target.value.length >= MIN_TRANSIT_NUMBER_LENGTH) {
            this.isBankingDetailsGridLoading = true;
            this.directDepositAPI.branch(event.target.value).subscribe(bankingDetails => {
                this.isBankingDetailsGridLoading = false;

                console.log('#Transit Search ', event.target.value, this.directDepositForm.controls.transitNumber.value);

                if (bankingDetails.length > 0) {
                    this.bankingDetails = bankingDetails;
                    this.showBankingDetailsGrid = bankingDetails.length > 0;
                    this.showBankingDetailsGridError = false;
                    this.bankingDetailsGridError = '';
                } else {
                    this.showTransitError('No branch matches this transit number.');
                }
                // }
            }, error => {
                console.error('Transit error', error);
                if (error.status == 404) { //not found
                    this.showTransitError('No branch matches this transit number.');
                } else {
                    this.showTransitError('We cannot process your request at this time.  Please contact Technical Support at 1.888.855.2477');
                    wcbUtility.LogError(error, 'Direct Deposit API Error');
                }
            });
        }
        this.validateForm();
    }

    showTransitError(errMessage: string) {
        this.isBankingDetailsGridLoading = false;
        this.bankingDetails = null;
        this.showBankingDetailsGrid = false;
        this.showBankingDetailsGridError = true;
        this.bankingDetailsGridError = errMessage;
        wcbUtility.LogTrace(`${errMessage} ${this.directDepositForm.controls.transitNumber.value}`);
    }

    scrollUpInputOnFocus(event: any) {
        setTimeout(function () {
            event.target.scrollIntoView(true);
        }, 100)
    }

    enterAccountNumberKeydown(event: any) {
        this.displayMessage.accountNumber = '';
    }

    enterAccountNumber(event: any) {
        this.accountNumber = event.target.value;
        this.validateForm();
    }

    validateForm(): boolean {
        const transitNum = this.directDepositForm.controls.transitNumber.value;
        //console.log('#transitNumber=', transitNum);
        if (this.accountNumber && this.accountNumber !== ''
            && this.accountNumber.length >= MIN_ACCOUNT_NUMBER_LENGTH
            && this.accountNumber.length <= MAX_ACCOUNT_NUMBER_LENGTH
            && this.selectionBoxVisible
            && transitNum
            && transitNum.length == MAX_TRANSIT_NUMBER_LENGTH) {
            //console.info('#continue enabled');
            this.continueButtonDisabled = false;
            return false;
        } else {
            //console.info('#continue disabled');
            this.continueButtonDisabled = true;
            return true;
        }
    }

    enableContinue(enabled: boolean) {
        this.selectionBoxVisible = enabled;
        this.continueButtonDisabled = !enabled;
    }

    confirmAccount(confirmed: boolean) {

        this.showBankingDetailsGrid = false;
        this.showBankingDetailsGridError = false;
        this.transitInputDisabled = confirmed;
        this.accountNumberInputDisabled = confirmed;
        this.showReviewSection = confirmed;
        this.showContinue = !confirmed;
        if (confirmed) {
            this.logger.TrackEvent("Event_Setup_ConfirmAccount_Confirmed");
            this.gotoHash('#confirmsubmit');
        }
        else {
            this.logger.TrackEvent("Event_Setup_ConfirmAccount_NotConfirmed");
            this.submitButtonDisabled = true;
            this.gotoHash('#top');
        }

    }

    gotoHash(hash: string): void {
        this.logger.StopTrackPage('timeSpentOnSetupDirectDepositPage');

        // the timeout is needed here because of processing time for show and hidden in the UI, otherwise the jump won't work.
        setTimeout(() => this.router.navigateByUrl('setup' + hash), 500);
    }

    consentCheckboxChange(event) {
        if (event.target.checked) {
            this.submitButtonDisabled = false;
        } else {
            this.submitButtonDisabled = true;
        }
    }

    backButtonClick() {
        this.logger.TrackEvent("Event_Setup_BackButton");
        this.signOut();
    }


    private signOut() {
        this.logger.StopTrackPage('timeSpentOnSetupDirectDepositPage');
        // todo REVIEW - What should we do if the exit url is null or 0 length?
        if (this.idamWorkerInfo?.exiturl?.length > 0) {
            // TODO REVIEW --- Why are we setting timer interval to navigate?      
            setTimeout(() => {
                window.open(this.idamWorkerInfo.exiturl, '_self');
            });
        }
    }

    confirmSubmit() {
        this.logger.TrackEvent("Event_Setup_ConfirmSubmit");
        this.save();
    }

    // todo REVIEW - this function needs some documentation. What is it doing? Why is it doing it? Is this useful or shareable to other functions?
    gotoConfirm(effDate: Date, statMessage: string) {

        const params = {
            idamWorkerInfo: this.idamWorkerInfo,
            transitNumber: this.directDepositForm.controls.transitNumber.value,
            accountNumber: this.directDepositForm.controls.accountNumber.value,
            bankName: this.selectedBankingDetailsInstitutionName,
            bankAddress: this.selectedBankingDetailsBranchAddressFull,
            effectiveDate: wcbUtility.GetDateString(effDate, this.datepipe),
            message: statMessage,
            status: this.enrollmentStatus
        };

        this.logger.StopTrackPage('timeSpentOnSetupDirectDepositPage');
        this.router.navigate(['confirmation'], { state: { data: params }, fragment: 'top' });
    }

    save() {
        if (this.submitButtonDisabled == true) {
            return;// don't submit if not enabled
        }

        document.body.style.cursor = "wait";
        this.displayMessage.submission = '';
        this.submitButtonDisabled = true;
        this.showLoading = true;

        const ccn = this.idamWorkerInfo.ccn; //this is the correct one to use later
        //var ccn=null; //this is for testing the error handling
        //var ccn=90194994243; // use this for local testing, this is a fake number
        const effDate = new Date();

        wcbUtility.ShowDebug('#Save data', this.directDepositForm, ccn, effDate);

        const accBody: Body2 = Body2.fromJS({
            customerCareNumber: ccn,
            financialInstitutionAccount: {
                accountNumber: this.directDepositForm.controls.accountNumber.value,
                transitNumber: this.directDepositForm.controls.transitNumber.value,
                institutionNumber: this.directDepositForm.controls.bankNumber.value,
                financialInstitutionAccountType: 0, // hardcode to UNKNOWN
                preferredIndicator: 1, // hardcode to TRUE
                effectiveDatetime: effDate,
                sourceSystemCode: 'DIRECTDEPO', // Indicate source origin
            }
        });

        try {
            this.directDepositAPI.workerFinancialInstitutionAccount(accBody).subscribe(
                (data: any) => {
                    //console.log('#Save Success: ', data);
                    wcbUtility.LogTrace("#Submission successful");
                    this.finishWait();
                    //this.gotoHash('#top'); //quick fix for now
                    this.gotoConfirm(effDate, "Submission successful");
                },
                (error: any) => {
                    wcbUtility.LogError(error, "#Submission failed at API");
                    this.finishWait();
                    wcbUtility.GotoErrorPage(this.router, DirectDepositConstants.ServerError + DirectDepositConstants.CallSupport);
                }
            );
        } catch (ex) {
            //display error message
            wcbUtility.LogError(ex, "#Submission failed at WEB");            
            this.finishWait();
            wcbUtility.GotoErrorPage(this.router, DirectDepositConstants.WebError + DirectDepositConstants.CallSupport);
        }

    }

    finishWait() {
        document.body.style.cursor = "default";
        this.submitButtonDisabled = false;
        this.showLoading = false;
    }
}
