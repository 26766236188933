<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h1 class="pagetitle py-4">{{ pageTitle }}</h1>
      </div>
    </div>
  </div>
</section>
<!--INFOPANEL-->
<section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <table class="my-3 d-none d-sm-block">
          <tbody>
            <tr>
              <td style="padding-right: 27px"><strong>Name:</strong></td>
              <td>
                {{ idamWorkerInfo.firstName }} {{ idamWorkerInfo.lastName }}
              </td>
            </tr>
            <tr>
              <td style="padding-right: 27px">
                <strong>Customer Care number:</strong>
              </td>
              <td>{{ idamWorkerInfo.ccn }}</td>
            </tr>
          </tbody>
        </table>

        <article class="mobile-infopanel d-sm-none pb-3 top-margin">
          <div><strong>Name:</strong></div>
          <div class="pb-1">
            {{ idamWorkerInfo.firstName }} {{ idamWorkerInfo.lastName }}
          </div>
          <div><strong>Customer Care number:</strong></div>
          <div class="pb-1">{{ idamWorkerInfo.ccn }}</div>
        </article>
      </div>
    </div>
  </div>
</section>
<!--INTRO-->
<section *ngIf="showSetupInstruction">
  <a name="top" id="top"></a>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <hr />
        <h2>Before you begin</h2>
        <p>
          Enjoy the benefits of having payments deposited directly into your
          bank account. Here’s what you need to know:
        </p>
        <div class="bullets">
          <ul>
            <li>
              The bank account must be in your name. Joint accounts are
              supported as long as your name is one of the names on the account.
            </li>
            <li>
              The account you provide must be a branch of a Canadian financial
              institution that is located in Canada.
            </li>
            <li>
              When you set up direct deposit, we’ll use this banking information
              for all payments for all claims you have (including any claims you
              might have in the future). If you wish, you can easily change your
              banking information or cancel direct deposit at any point.
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
<!--APP FORM-->
<section id="bankingdetails">
  <div class="container">
    <hr />
    <div class="row">
      <div class="col-md-12">
        <h2>{{ editTitle }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="samplechek d-md-none">
          <p class="mb-4 mt-2">{{ chequeInstruction }}</p>
          <img
            class="img-fluid d-block pb-3"
            src="/assets/img/cheque-mobile.png"
          />
        </div>
        <div *ngIf="!showSetupInstruction" class="pb-2">
          Your current banking information is displayed below.
        </div>
        <form novalidate (ngSubmit)="save()" [formGroup]="directDepositForm">
          <div class="form-group">
            <label>Transit (branch) number</label>
            <input
              type="text"
              class="form-control"
              formControlName="transitNumber"
              id="transitNumberId"
              pattern="\d*"
              [ngClass]="{ 'is-invalid': displayMessage.transitNumber }"
              mask="00000"
              value="{{ selectedBankingDetailsTransitNumber }}"
              (input)="searchTransit($event)"
              [attr.disabled]="transitInputDisabled ? '' : null"
              (focus)="scrollUpInputOnFocus($event)"
            />

            <span class="invalid-message" id="transitNumberErr">{{
              displayMessage.transitNumber
            }}</span>
            <span class="invalid-message" *ngIf="showBankingDetailsGridError">{{
              bankingDetailsGridError
            }}</span>
            <span
              *ngIf="
                isBankingDetailsGridLoading && !showBankingDetailsGridError
              "
            >
              <div class="row">
                <div class="col-12 py-5 text-center">
                  <div class="sk-three-bounce">
                    <div class="sk-child sk-bounce1"></div>
                    <div class="sk-child sk-bounce2"></div>
                    <div class="sk-child sk-bounce3"></div>
                    <div class="sk-child sk-bounce4"></div>
                  </div>
                  <span><strong>Loading</strong></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12"></div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row"></div>
                </div>
              </div>
            </span>
            <div
              class="table-responsive"
              *ngIf="showBankingDetailsGrid"
              class="dropdown"
            >
              <table class="table table-striped white dropdown">
                <tbody class="bottomborderline hoverTable">
                  <tr
                    *ngFor="let item of bankingDetails; index as i"
                    (click)="selectTransit(i)"
                    style="border-color: red"
                  >
                    <td>
                      {{ item.transitNumber }} | {{ item.institutionNumber }} |
                      {{ item.institutionName }} <br />
                      {{
                        item.branchAddress +
                          " " +
                          item.city +
                          " " +
                          item.provinceCode +
                          " " +
                          item.postalCode
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div *ngIf="transitSelected">
            <div class="borderedbox" *ngIf="selectionBoxVisible">
              <table class="my-3 d-none d-sm-block grey-box">
                <tbody>
                  <tr>
                    <td colspan="2" class="pb-3">You have selected</td>
                  </tr>
                  <tr>
                    <td style="padding-right: 27px; vertical-align: top">
                      <strong>Institution:</strong>
                    </td>
                    <td *ngIf="selectedBankingDetailsInstitutionName">
                      {{ selectedBankingDetailsInstitutionName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-right: 27px; vertical-align: top">
                      <strong class="nowrap">Branch address:</strong>
                    </td>
                    <td *ngIf="selectedBankingDetailsBranchAddressFull">
                      {{ selectedBankingDetailsBranchAddressFull }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <article class="mobile-infopanel d-sm-none pb-3 grey-box">
                <div class="pb-1">You have selected</div>
                <div><b>Institution:</b></div>
                <div class="pb-1" *ngIf="selectedBankingDetailsInstitutionName">
                  {{ selectedBankingDetailsInstitutionName }}
                </div>
                <div><b>Branch address:</b></div>
                <div
                  class="pb-1"
                  *ngIf="selectedBankingDetailsBranchAddressFull"
                >
                  {{ selectedBankingDetailsBranchAddressFull }}
                </div>
              </article>
            </div>

            <input
              type="hidden"
              formControlName="bankNumber"
              id="bankNumberId"
              [ngClass]="{ 'is-invalid': displayMessage.bankNumber }"
            />
            <span class="invalid-message" id="bankNumberErr">{{
              displayMessage.bankNumber
            }}</span>

            <div class="form-group mb-3">
              <label>Account number</label>
              <input
                type="text"
                class="form-control col-lg-6 col-12"
                formControlName="accountNumber"
                pattern="\d*"
                id="accountNumberId"
                mask="000000000000"
                [ngClass]="{ 'is-invalid': displayMessage.accountNumber }"
                value="{{ accountNumber }}"
                (keydown)="enterAccountNumberKeydown($event)"
                (keyup)="enterAccountNumber($event)"
                [attr.disabled]="accountNumberInputDisabled ? '' : null"
                style="width: 100%"
                (focus)="scrollUpInputOnFocus($event)"
              />
              <span class="invalid-message" id="accountNumberErr">{{
                displayMessage.accountNumber
              }}</span>
            </div>

            <div *ngIf="showContinue">
              <a
                class="btn btn-md btn-outline-blue btn-md col-lg-2 col-md-3 col-sm-12 col-12"
                style="margin-right: 0.5rem"
                id="backbutton"
                (click)="backButtonClick()"
                >Back</a
              >
              <a
                class="btn btn-md btn-blue btn-md col-lg-3 col-md-4 col-sm-12 col-12"
                id="validatebutton"
                *ngIf="
                  !continueButtonDisabled;
                  else continueButtonDisabledTemplate
                "
                (click)="confirmAccount(true)"
                >Continue</a
              >
              <ng-template #continueButtonDisabledTemplate>
                <a
                  class="btn btn-md btn-grey btn-md col-lg-3 col-md-4 col-sm-12 col-12"
                  id="validatebutton"
                  >Continue</a
                >
              </ng-template>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-6">
        <div class="samplechek d-none d-md-block">
          <p class="mb-4 mt-sm-2">{{ chequeInstruction }}</p>
          <img class="img-fluid d-block" src="assets/img/cheque.jpg" />
        </div>
      </div>
    </div>
  </div>
</section>
<!--APP FORM-->
<!--CONFIRM-->
<section id="confirmdetails" *ngIf="showReviewSection">
  <a name="cofirmsubmit" id="confirmsubmit"></a>
  <hr />
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2>Review and submit</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p class="mb-3">Please confirm your banking information.</p>
        <table class="my-3 d-none d-sm-block">
          <tbody>
            <tr>
              <td style="padding-right: 27px"><strong>Institution:</strong></td>
              <td>{{ selectedBankingDetailsInstitutionName }}</td>
            </tr>
            <tr>
              <td style="padding-right: 27px">
                <strong>Branch address:</strong>
              </td>
              <td>{{ selectedBankingDetailsBranchAddressFull }}</td>
            </tr>
            <tr>
              <td style="padding-right: 27px">
                <strong>Transit (branch) number:</strong>
              </td>
              <td>{{ selectedBankingDetailsTransitNumber }}</td>
            </tr>
            <tr>
              <td><strong>Account number:</strong></td>
              <td>{{ accountNumber }}</td>
            </tr>
          </tbody>
        </table>
        <article class="mobile-infopanel d-sm-none pb-3">
          <div><b>Institution:</b></div>
          <div class="pb-1">{{ selectedBankingDetailsInstitutionName }}</div>
          <div><b>Branch address:</b></div>
          <div class="pb-1">{{ selectedBankingDetailsBranchAddressFull }}</div>
          <div><b>Transit (branch) number:</b></div>
          <div class="pb-1">{{ selectedBankingDetailsTransitNumber }}</div>
          <div><b>Account number:</b></div>
          <div class="pb-1">{{ accountNumber }}</div>
        </article>
        <a
          id="resetbutton"
          class="mb-3 btn btn-outline-blue btn-sm mr-2 mb-width"
          (click)="confirmAccount(false)"
          >Edit</a
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="outsidebox mt-2">
          <div class="innerbox">
            <div class="confirmationbox d-flex">
              <div>
                <label class="container-checkbox">
                  <input
                    id="payrollCheckbox"
                    type="checkbox"
                    name="disclaimer"
                    (change)="consentCheckboxChange($event)"
                  />
                  <span class="checkmark-checkbox"></span>
                </label>
              </div>
              <span
                >By checking this box:<br /><br />
                <div class="bullets">
                  <ul style="padding-left: 11px">
                    <li>
                      I confirm the banking information listed above is correct,
                      authorize WorkSafeBC to deposit payments into the account
                      identified above, and certify that I am authorized to set
                      up direct deposit for this claim.
                    </li>
                    <li>
                      I understand that once direct deposit has been set up,
                      WorkSafeBC will use this banking information for all
                      payments for current and future claims unless I request
                      cancellation of payment by direct deposit from
                      WorkSafeBC.&nbsp;I understand I may cancel this
                      authorization at any time and acknowledge WorkSafeBC make
                      need up to five days to process the cancellation request.
                    </li>
                    <li>
                      I understand that WorkSafeBC will disclose this banking
                      information to its service providers for the purpose of
                      facilitating payments.
                    </li>
                  </ul>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-md-12" *ngIf="!showLoading">
        <button
          class="btn btn-md btn-outline-blue mr-2 mb-2 mb-width"
          (click)="confirmAccount(false)"
        >
          Cancel
        </button>
        <button
          id="confirmbutton"
          class="btn btn-md btn-blue btn-md mr-2 mb-width"
          (click)="confirmSubmit()"
          [disabled]="submitButtonDisabled"
        >
          Save
        </button>
        <div class="text-danger">{{ displayMessage.submission }}</div>
      </div>
      <div *ngIf="showLoading" class="backdrop">
        <div class="text-center backmessage">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
            <div class="sk-child sk-bounce4"></div>
          </div>
          <span><strong>Submitting...</strong></span>
        </div>
      </div>
    </div>
  </div>
</section>

<!--CONSENT-->
