import { FormGroup } from '@angular/forms';

// Generic validator for Reactive forms
// Implemented as a class, not a service, so it can retain state for multiple forms.
export class GenericValidator {
  // Provide the set of valid validation messages
  // Stucture:
  // controlName1: {
  //     validationRuleName1: 'Validation Message.',
  //     validationRuleName2: 'Validation Message.'
  // },
  // controlName2: {
  //     validationRuleName1: 'Validation Message.',
  //     validationRuleName2: 'Validation Message.'
  // }
  constructor(
    private validationMessages: { [key: string]: { [key: string]: string } }
  ) {}

  // Processes each control within a FormGroup
  // And returns a set of validation messages to display
  // Structure
  // controlName1: 'Validation Message.',
  // controlName2: 'Validation Message.'
  processMessages(container: FormGroup): { [key: string]: string } {
    const messages: { [key: string]: string } = {};
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        const c = container.controls[controlKey];
        // If it is a FormGroup, process its child controls.
        if (c instanceof FormGroup) {
          const childMessages = this.processMessages(c);
          Object.assign(messages, childMessages);
        } else {
          // Only validate if there are validation messages for the control
          if (this.validationMessages[controlKey]) {
            messages[controlKey] = '';
            if ((c.dirty || c.touched) && c.errors) {
              Object.keys(c.errors).map((messageKey) => {
                if (this.validationMessages[controlKey][messageKey]) {
                  messages[controlKey] +=
                    this.validationMessages[controlKey][messageKey] + ' ';
                } else if (this.validationMessages['default'][messageKey]) {
                  // Lazy man validation
                  messages[controlKey] +=
                    this.validationMessages['default'][messageKey] + ' ';
                }
              });
            }
          }
        }
      }
    }
    return messages;
  }

  /*

  // JDM : 2021-03-11 getErrorCount() is NOT used - and "errors!.length" seems problematic anyway.

  getErrorCount(container: FormGroup): number {
    let errorCount :number = 0; 
    for (const controlKey in container.controls) {
      if (container.controls.hasOwnProperty(controlKey)) {
        if (container.controls[controlKey].errors) {
          wcbUtility.ShowDebug('#ErrorObj',container.controls[controlKey].errors);
         
          let controlErrorCount : number = container.controls[controlKey].errors!.length;
          if (controlErrorCount != undefined) {
            errorCount += controlErrorCount; 
          }
          // errorCount += Object.keys(container.controls[controlKey].errors).length;
          wcbUtility.ShowDebug('#ErrorCount',errorCount);
        }
      }
    }
    return errorCount;
  }
  */
}
