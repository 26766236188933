import { Component, OnInit } from '@angular/core';
import { DirectDepositConstants } from '../../models/directdeposit.constants';
import { wcbUtility } from '../../shared/helpers/wcbUtility';

@Component({
  selector: 'app-banking-error',
  templateUrl: './banking-error.component.html',
  styleUrls: ['./banking-error.component.scss']
})
export class BankingErrorComponent implements OnInit {

  displayError: string = '';
  constructor() {
    const params = history.state.data;
    if (params === undefined) {
      if(sessionStorage.getItem('error') == null) 
        this.displayError = DirectDepositConstants.CallSupport;
      else
        this.displayError = sessionStorage.getItem('error');
    } else {
      const error: string = params['error'];
      sessionStorage.setItem('error', error);
      //Update
      wcbUtility.ShowDebug("#History State", params);
      this.displayError = error;
    }

  }

  ngOnInit(): void {
  }

}
