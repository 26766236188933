<a name="top" id="top"></a>
<section *ngIf="showError">
  <div class="container">
    <div class="text-danger">
      This page cannot be refreshed. Please do not submit again.
    </div>
  </div>
</section>
<section *ngIf="!showError">
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="pagetitle py-4">
            Thank you for setting up direct deposit&nbsp;
          </h1>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <p>
          We will send you an email (at <b>{{ idamWorkerInfo.email }}</b
          >) once your banking information has been updated to confirm that
          payments will be made via direct deposit. This may take up to 15
          minutes.
        </p>
        <div class="outsidebox">
          <div class="innerbox">
            <div class="confirmationbox">
              <table class="my-3 infopanel d-none d-sm-block">
                <tbody>
                  <tr>
                    <td style="width: 240px"><strong>Name:</strong></td>
                    <td>
                      {{ idamWorkerInfo.firstName }}
                      {{ idamWorkerInfo.lastName }}
                    </td>
                  </tr>
                  <tr>
                    <td style="padding-right: 27px">
                      <strong>Customer Care number:</strong>
                    </td>
                    <td>{{ idamWorkerInfo.ccn }}</td>
                  </tr>
                </tbody>
              </table>
              <table class="my-3 infopanel d-none d-sm-block">
                <tbody>
                  <tr>
                    <td style="width: 240px"><strong>Institution:</strong></td>
                    <td>{{ displayMessage.bankName }}</td>
                  </tr>
                  <tr>
                    <td style="padding-right: 27px">
                      <strong>Branch address:</strong>
                    </td>
                    <td>{{ displayMessage.bankAddress }}</td>
                  </tr>
                  <tr>
                    <td style="padding-right: 27px">
                      <strong>Transit (branch) number:</strong>
                    </td>
                    <td>{{ displayMessage.transitNumber }}</td>
                  </tr>
                  <tr>
                    <td><strong>Account number:</strong></td>
                    <td>{{ displayMessage.accountNumber }}</td>
                  </tr>
                  <tr>
                    <td><strong>Submitted date:</strong></td>
                    <td>{{ displayMessage.effectiveDate }}</td>
                  </tr>
                </tbody>
              </table>
              <article class="mobile-infopanel d-sm-none pb-3">
                <div><b>Name:</b></div>
                <div class="pb-1">{{ displayMessage.workerName }}</div>
                <div><b>Customer Care number:</b></div>
                <div class="pb-1">{{ displayMessage.workerCCN }}</div>
                <div class="mt-3"><b>Institution:</b></div>
                <div class="pb-1">{{ displayMessage.bankName }}</div>
                <div><b>Branch address:</b></div>
                <div class="pb-1">{{ displayMessage.bankAddress }}</div>
                <div><b>Transit (branch) number:</b></div>
                <div class="pb-1">{{ displayMessage.transitNumber }}</div>
                <div><b>Account number:</b></div>
                <div class="pb-1">{{ displayMessage.accountNumber }}</div>
                <div><b>Submitted date:</b></div>
                <div class="pb-1">{{ displayMessage.effectiveDate }}</div>
              </article>
            </div>
          </div>
        </div>

        <h2 class="mt-3">What to expect next</h2>
        <div class="bullets">
          <ul>
            <li>
              It will take up to 15 minutes for your direct deposit information
              to be updated. Any payments already in progress will not be
              affected.​
            </li>
            <li>
              It will take up to three days from the date we process a payment
              for the funds to appear in your account.&nbsp;
            </li>
            <li>
              We will use this banking information for all payments for all
              claims you have (including any claims you might have in the
              future).
            </li>
          </ul>
        </div>
        <button
          class="btn btn-md btn-blue mb-2 mb-width"
          style="margin-right: 0.5rem"
          id="exitbutton"
          (click)="exitButtonClick()"
        >
          Exit
        </button>
      </div>
    </div>
  </div>
</section>

<!--SurveyBar Here-->
<ctrl-surveybar
  url="https://eds.ca.matchbox.maruhub.com/survey/enter/s/ESV-dy02-952707622"
  *ngIf="showSurvey"
>
  <h2>How was your experience with setting up direct deposit?</h2>
  <p>
    We’ve recently launched this application to set up direct deposit online,
    and would love to hear your feedback. How can we improve? Let us know
    through our short, anonymous survey.
  </p>
</ctrl-surveybar>
